import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./aktuelles.scss"
import BlockContent from "../components/block-content"
import OpenTimes from "../components/opentimes"

const AktuellesPage = ({ data: { sanityNewsPageSettings: data } }) => {
  return (
    <Layout page="aktuelles" noAlerts>
      <SEO title={data.title} />
      <div className="display-header">
        <h2>{data.heading}</h2>
      </div>
      <div className="body">
        <BlockContent blocks={data._rawBody} />
      </div>
      <hr />
      <div>
        <div className="display-header">
          <h2>Öffnungszeiten</h2>
        </div>
        <OpenTimes />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query NewsPageSettingsQuery {
    sanityNewsPageSettings(_id: { eq: "newsPageSettings" }) {
      _rawBody
      title
      heading
    }
  }
`

export default AktuellesPage
